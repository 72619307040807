import { useState, useEffect } from "react";
import { EVENT_DOWNLOAD_SAMPLE } from "../services/analytics";
import { consoleDebug } from "../utils/Utils";
import ReslicoApi from "../services/network/api";
import DownloadButton from "./DownloadButton";
import { eventTracker } from "../services/analytics/analytics";
const reslicoApi = new ReslicoApi();
const api = {
  country: reslicoApi.getCountrySampleData,
  provider: reslicoApi.getProviderSampleData,
};

export default function SampleDownloadButton(props) {
  const { screen, type, entityName } = props;
  const [downloadingSample, setDownloadingSample] = useState(null);
  const [csvData, setCsvData] = useState(false);
  const [csvFilename, setCsvFilename] = useState("reslico_sample.csv");

  useEffect(() => {
    if (csvData && csvFilename) {
      const encodedUri = encodeURIComponent("\uFEFF" + csvData);
      const link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
      link.setAttribute("download", csvFilename);
      document.body.appendChild(link);
      link.click();
      setCsvData(false);
    }
  }, [csvData, csvFilename]);

  const getSample = () => {
    setDownloadingSample(true);

    api[type]
      .bind(reslicoApi)(entityName)
      .then((response) => {
        setCsvData(response.data);
        setCsvFilename(response.filename);
      })
      .catch((e) => consoleDebug("getSample error: " + e))
      .finally(() => setDownloadingSample(false));
  };

  return (
    <>
      <DownloadButton
        onClick={() => {
          getSample();
          eventTracker(screen, EVENT_DOWNLOAD_SAMPLE, entityName);
        }}
        showSpinner={downloadingSample}
      />
    </>
  );
}

import React from "react";
import Nav from "react-bootstrap/Nav";
import mainPageEvent, {
  EVENT_PRIVACY_CLICKED,
  EVENT_TOS_CLICKED,
} from "../../services/analytics";

export default function Footer() {
  return (
    <div className="main-footer text-center">
      <div className="container">
        <div className="row">
          {/* <div class="col-md-1 col-sm-1"> */}
          <Nav.Link href="/">
            <p className="fw-bold">Home</p>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              mainPageEvent(EVENT_PRIVACY_CLICKED);
            }}
            href="/privacy"
          >
            <p className="fw-bold">Privacy</p>
          </Nav.Link>
          <Nav.Link
            onClick={() => {
              mainPageEvent(EVENT_TOS_CLICKED);
            }}
            href="/terms_of_service"
          >
            <p className="fw-bold">Terms of Service</p>
          </Nav.Link>
          {/* </div> */}
        </div>
        <p>Copyright © 2022 Reslico. All Rights Reserved</p>
      </div>
    </div>
  );
}

import React, { useEffect } from "react";
import { useFiefAuth } from "@fief/fief/react";
import { useNavigate } from "react-router-dom";
import { Container, Spinner } from "react-bootstrap";

const Callback = () => {
  const fiefAuth = useFiefAuth();
  const navigate = useNavigate();

  useEffect(() => {
    fiefAuth
      .authCallback(
        `${window.location.protocol}//${window.location.host}/callback`
      )
      .then(() => {
        navigate("/dashboard/countries");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [fiefAuth, navigate]);

  return (
    <Container
      fluid
      className="d-flex flex-column align-items-center justify-content-center text-center min-vh-100"
    >
      <h3>
        Logged in!
        <br />
        Returning to dashboard...
      </h3>
      <Spinner animation="border" variant="primary" />
    </Container>
  );
};

export default Callback;

import { Button } from "react-bootstrap";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CSpinner,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import React, { useState } from "react";
import SampleDownloadButton from "../../components/SampleDownloadButton";
import ListingsCounts from "../../data_components/ListingsCounts";
import {
  EVENT_CHANGED_TABLE_OPTION,
  screenLoadedEvent,
  tablesPageEvent,
} from "../../services/analytics";
import { numberWithCommas } from "../../utils/Numbers";

const options = {
  last30Days: "Last 30 days, including price history",
  allListings: "All listings history, without price history",
};
const forsaleKey = {
  last30Days: "forsale",
  allListings: "all_forsale",
};
const rentKey = {
  last30Days: "rent",
  allListings: "all_rent",
};

export default function DataTablePage(props) {
  const [selectedOption, setSelectedOption] = useState("last30Days");
  const {
    dataSelector,
    entityType,
    entityTitle,
    entityRenderer,
    entityColSpan = 1,
  } = props;

  screenLoadedEvent(entityTitle);

  return (
    <>
      <ListingsCounts />

      {dataSelector && Object.keys(dataSelector).length > 0 ? (
        <>
          Select data set type:
          <CDropdown className="ms-3 " variant="btn-group">
            <CDropdownToggle color={"primary"}>
              {options[selectedOption]}
            </CDropdownToggle>
            <CDropdownMenu>
              {Object.keys(options).map((option) => (
                <CDropdownItem
                  key={option}
                  onClick={(event) => {
                    event.preventDefault();
                    tablesPageEvent(
                      entityTitle,
                      EVENT_CHANGED_TABLE_OPTION,
                      option
                    );
                    setSelectedOption(option);
                  }}
                >
                  {options[option]}
                </CDropdownItem>
              ))}
            </CDropdownMenu>
          </CDropdown>
          <CTable striped className="mt-3">
            <CTableHead>
              <CTableRow>
                <CTableHeaderCell scope="col" colSpan={entityColSpan}>
                  {entityTitle}
                </CTableHeaderCell>
                <CTableHeaderCell scope="col">For Sale</CTableHeaderCell>
                <CTableHeaderCell scope="col">For Rent</CTableHeaderCell>
                <CTableHeaderCell scope="col">Last Updated</CTableHeaderCell>
                <CTableHeaderCell scope="col">Download Sample</CTableHeaderCell>
                <CTableHeaderCell scope="col">
                  Download All Data
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody>
              {Object.keys(dataSelector).map((dataEntityName) =>
                dataSelector[dataEntityName][forsaleKey[selectedOption]] <
                process.env.REACT_APP_MIN_FORSALE_LSITINGS ? null : (
                  <CTableRow key={dataEntityName}>
                    {entityRenderer(dataEntityName)}
                    <CTableDataCell>
                      <strong>
                        {numberWithCommas(
                          dataSelector[dataEntityName][
                            forsaleKey[selectedOption]
                          ]
                        )}
                      </strong>
                    </CTableDataCell>
                    <CTableDataCell>
                      <strong>
                        {numberWithCommas(
                          dataSelector[dataEntityName][rentKey[selectedOption]]
                        )}
                      </strong>
                    </CTableDataCell>
                    <CTableDataCell>
                      <strong>
                        {isEmpty(dataSelector[dataEntityName].timestamp)
                          ? null
                          : moment(
                              dataSelector[dataEntityName].timestamp
                            ).format("LLL")}
                      </strong>
                    </CTableDataCell>
                    <CTableDataCell>
                      {isEmpty(
                        dataSelector[dataEntityName].timestamp
                      ) ? null : (
                        <SampleDownloadButton
                          screen={entityTitle}
                          type={entityType}
                          entityName={dataEntityName}
                        />
                      )}
                    </CTableDataCell>
                    <CTableDataCell>
                      <Button
                        className="btn btn-primary px-5"
                        href={`mailto:reslico@reslico.com?subject=Request for all data for ${dataEntityName}`}
                      >
                        <strong>Contact us</strong>
                      </Button>
                    </CTableDataCell>
                  </CTableRow>
                )
              )}
            </CTableBody>
          </CTable>
        </>
      ) : (
        <CSpinner />
      )}
    </>
  );
}

import React from "react";
import CIcon from "@coreui/icons-react";
import { cilGlobeAlt, cilNewspaper } from "@coreui/icons";
import { CNavItem, CNavTitle } from "@coreui/react";

const _nav = [
  {
    component: CNavTitle,
    name: "Download Data",
  },
  {
    component: CNavItem,
    name: "Countries",
    to: "/dashboard/countries",
    icon: <CIcon icon={cilGlobeAlt} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Providers",
    to: "/dashboard/providers",
    icon: <CIcon icon={cilNewspaper} customClassName="nav-icon" />,
  },
];

export default _nav;

import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export default class ReslicoApi {
  getHeadersForAccessToken(accessToken) {
    return accessToken != null
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {};
  }

  requestGET(path, headers = {}, accessToken = null) {
    const url = `${apiUrl}/${path}`;
    const config = {
      headers: {
        ...headers,
        ...this.getHeadersForAccessToken(accessToken),
      },
    };
    return axios
      .get(url, config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  requestPOST(path, data, headers = {}, accessToken = null) {
    const url = `${apiUrl}/${path}`;
    const config = {
      headers: {
        ...headers,
        ...this.getHeadersForAccessToken(accessToken),
      },
    };
    return axios
      .post(url, data, config)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getCountriesAmounts() {
    return this.requestGET("countries")
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getProviderAmounts() {
    return this.requestGET("providers")
      .then((response) => {
        return Promise.resolve(response.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getSampleData(entityType, entityName) {
    return this.requestGET(`sample?${entityType}=${entityName}`)
      .then((response) => {
        const filename = response.headers["filename"] || "reslico_sample.csv";
        return Promise.resolve({ data: response.data, filename: filename });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getCountrySampleData(country) {
    return this.getSampleData("country", country);
  }

  getProviderSampleData(provider) {
    return this.getSampleData("provider", provider);
  }

  postContactUsForm(data) {
    return this.requestPOST("contact_us", data)
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

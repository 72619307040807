import { useFiefAuth } from "@fief/fief/build/esm/react";
import React, { useCallback, useEffect } from "react";

const Logout = () => {
  const fiefAuth = useFiefAuth();
  const logout = useCallback(() => {
    fiefAuth.logout(`${window.location.protocol}//${window.location.host}`);
  }, [fiefAuth]);

  useEffect(() => {
    logout();
  }, [fiefAuth, logout]);

  return <></>;
};

export default Logout;

import React from "react";

import Header from "./Header";
import Features from "./Features";
import Samples from "./Samples";
import ContactUs from "./ContactUs";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Main() {
  return (
    <>
      <Navbar />
      <Header />
      <Features id="features" />
      <Samples id="samples" />
      <ContactUs id="contact_us" />
      <Footer />
    </>
  );
}

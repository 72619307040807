import React, { useEffect } from "react";
import ReslicoApi from "../services/network/api";
import { consoleDebug } from "../utils/Utils";
import { useDispatch, useSelector } from "react-redux";
const reslicoApi = new ReslicoApi();

export default function ListingsCounts(props) {
  const dispatch = useDispatch();
  const countriesListingsCounts = useSelector(
    (state) => state.countriesListingsCounts
  );
  const providersListingsCounts = useSelector(
    (state) => state.providersListingsCounts
  );

  useEffect(() => {
    if (countriesListingsCounts == null) {
      consoleDebug("Attempt to retrieve countries listings count...");
      reslicoApi
        .getCountriesAmounts()
        .then((data) => {
          dispatch({
            type: "updateCountriesListingsCounts",
            ...data,
          });
        })
        .catch((x) => {
          dispatch({
            type: "updateCountriesListingsCounts",
            ...{},
          });
        });
    }
  }, [dispatch, countriesListingsCounts]);

  useEffect(() => {
    if (providersListingsCounts == null) {
      consoleDebug("Attempt to retrieve providers listings count...");
      reslicoApi
        .getProviderAmounts()
        .then((data) => {
          dispatch({
            type: "updateProvidersListingsCounts",
            ...data,
          });
        })
        .catch((x) => {
          dispatch({
            type: "updateProvidersListingsCounts",
            ...{},
          });
        });
    }
  }, [dispatch, providersListingsCounts]);

  return <></>;
}

import { createStore } from "redux";

const initialState = {
  sidebarShow: true,
  countriesListingsCounts: null,
  providersListingsCounts: null,
};

const changeState = (state = initialState, { type, ...data }) => {
  switch (type) {
    case "set":
      return { ...state, ...data };
    case "updateCountriesListingsCounts":
      return {
        ...state,
        countriesListingsCounts: data,
      };
    case "updateProvidersListingsCounts":
      return {
        ...state,
        providersListingsCounts: data,
      };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;

import Footer from "../main/Footer";
import MainNavbar from "../main/Navbar";
import "./Legal.css";

export default function TermsOfService(props) {
  return (
    <>
      <MainNavbar />
      <div className="paragraph">
        <h1>Terms of Service</h1>
        <p>
          Welcome to Reslico! These Terms of Service (<b>“Terms”</b>) govern
          your access to and use of Reslico’s website, data, services, and
          content (collectively, the <b>“Services”</b>). Please read these Terms
          carefully before using the Services. By accessing or using the
          Services, you agree to be bound by these Terms. If you do not agree to
          these Terms, you may not access or use the Services.
        </p>

        <h2>1. Overview</h2>
        <p>
          Reslico is a technology company that provides data and raw data of
          real estate listings collected from different websites (<b>“Data”</b>
          ). Reslico is not responsible for the accuracy, reliability, or
          completeness of the Data. The Data is provided “as is” without any
          warranty, express or implied, including without limitation, warranties
          of merchantability or fitness for a particular purpose. Reslico makes
          no representations or warranties regarding the reliability, accuracy,
          or completeness of the Data.
        </p>

        <h2>2. Use of the Services</h2>
        <p>
          You may use the Services only for lawful purposes and in accordance
          with these Terms. You agree not to use the Services:
          <ul>
            <li>
              In any way that violates any applicable federal, state, local, or
              international law or regulation (including, without limitation,
              any laws regarding the export of data or software to and from the
              US or other countries).
            </li>
            <li>
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information, or
              otherwise.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or
              promotional material, including any “junk mail,” “chain letter,”
              “spam,” or any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate Reslico, a Reslico
              employee, another user, or any other person or entity.
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone’s
              use or enjoyment of the Services, or which, as determined by
              Reslico, may harm Reslico or users of the Services, or expose them
              to liability.
            </li>
          </ul>
        </p>

        <h2>3. Intellectual Property Rights</h2>
        <p>
          The Services and their entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof), are owned by Reslico, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws. These Terms permit
          you to use the Services for your personal, non-commercial use only.
          You must not reproduce, distribute, modify, create derivative works
          of, publicly display, publicly perform, republish, download, store, or
          transmit any of the material on our Services, except as follows:
          <ul>
            <li>
              Your computer may temporarily store copies of such materials in
              RAM incidental to your accessing and viewing those materials.
            </li>
            <li>
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
            </li>
            <li>
              You may print or download one copy of a reasonable number of pages
              of the Services for your own personal, non-commercial use and not
              for further reproduction, publication, or distribution. If we
              provide desktop, mobile, or other applications for download, you
              may download a single copy to your computer or mobile device
              solely for your own personal, non-commercial use, provided you
              agree to be bound by our end user license agreement for such
              applications. If we provide social media features with certain
              content, you may take such actions as are enabled by such
              features. You must not:
              <ul>
                <li> Modify copies of any materials from the Services. </li>
                <li>
                  Use any illustrations, photographs, video or audio sequences,
                  or any graphics separately from the accompanying text.
                </li>
                <li>
                  Delete or alter any copyright, trademark, or other proprietary
                  rights notices from copies of materials from the Services.
                </li>
              </ul>
            </li>
          </ul>
          You must not access or use for any commercial purposes any part of the
          Services or any services or materials available through the Services.
          If you print, copy, modify, download, or otherwise use or provide any
          other person with access to any part of the Services in breach of the
          Terms, your right to use the Services will stop immediately and you
          must, at our option, return or destroy any copies of the materials you
          have made. Any unauthorized use may also violate applicable laws,
          including without limitation copyright and trademark laws and
          applicable communications regulations and statutes.
        </p>

        <h2>4. Disclaimer of Warranties</h2>
        <p>
          You understand that Reslico cannot and does not guarantee or warrant
          that files available for downloading from the internet or the Services
          will be free of viruses or other destructive code. You are responsible
          for implementing sufficient procedures and checkpoints to satisfy your
          particular requirements for anti-virus protection and accuracy of data
          input and output, and for maintaining a means external to our site for
          any reconstruction of any lost data. <br />
          TO THE FULLEST EXTENT PROVIDED BY LAW, RESLICO WILL NOT BE LIABLE FOR
          ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
          VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT
          YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY
          MATERIAL DUE TO YOUR USE OF THE SERVICES OR ANY SERVICES OR ITEMS
          OBTAINED THROUGH THE SERVICES OR TO YOUR DOWNLOADING OF ANY MATERIAL
          POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT. <br /> YOUR USE OF THE
          SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE SERVICES IS AT YOUR OWN RISK. THE SERVICES, THEIR CONTENT, AND ANY
          SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES ARE PROVIDED ON AN “AS
          IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND,
          EITHER EXPRESS OR IMPLIED. NEITHER RESLICO NOR ANY PERSON ASSOCIATED
          WITH RESLICO MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
          COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
          AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER
          RESLICO NOR ANYONE ASSOCIATED WITH RESLICO REPRESENTS OR WARRANTS THAT
          THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
          THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES
          IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT
          THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES
          WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
        </p>

        <h2>5. Limitation of Liability</h2>
        <p>
          TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL RESLICO, ITS
          AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
          OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
          LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
          INABILITY TO USE, THE SERVICES, ANY WEBSITES LINKED TO IT, ANY CONTENT
          ON THE SERVICES OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT,
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES,
          INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING,
          EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS
          OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA,
          AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT,
          OR OTHERWISE, EVEN IF FORESEEABLE.
        </p>

        <h2>6. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless Reslico, its
          affiliates, licensors, and service providers, and its and their
          respective officers, directors, employees, contractors, agents,
          licensors, suppliers, successors, and assigns from and against any
          claims, liabilities, damages, judgments, awards, losses, costs,
          expenses, or fees (including reasonable attorneys’ fees) arising out
          of or relating to your violation of these Terms or your use of the
          Services, including, but not limited to, any use of the Services’
          content, services, and products other than as expressly authorized in
          these Terms or your use of any information obtained from the Services.
        </p>

        <h2>7. Governing Law and Jurisdiction</h2>
        <p>
          All matters relating to the Services and these Terms and any dispute
          or claim arising therefrom or related thereto (in each case, including
          non-contractual disputes or claims), shall be governed by and
          construed in accordance with the internal laws of the State of
          California without giving effect to any choice or conflict of law
          provision or rule. <br />
          Any legal suit, action, or proceeding arising out of, or related to,
          these Terms or the Services shall be instituted exclusively in the
          federal courts of the United States or the courts of the State of
          California in each case located in the City of San Francisco and
          County of San Francisco although we retain the right to bring any
          suit, action, or proceeding against you for breach of these Terms in
          your country of residence or any other relevant country. You waive any
          and all objections to the exercise of jurisdiction over you by such
          courts and to venue in such courts.
        </p>

        <h2>8. Waiver and Severability</h2>
        <p>
          No waiver by Reslico of any term or condition set forth in these Terms
          shall be deemed a further or continuing waiver of such term or
          condition or a waiver of any other term or condition, and any failure
          of Reslico to assert a right or provision under these Terms shall not
          constitute a waiver of such right or provision. <br />
          If any provision of these Terms is held by a court or other tribunal
          of competent jurisdiction to be invalid, illegal, or unenforceable for
          any reason, such provision shall be eliminated or limited to the
          minimum extent such that the remaining provisions of the Terms will
          continue in full force and effect.
        </p>

        <h2>9. Entire Agreement</h2>
        <p>
          These Terms and our Privacy Policy constitute the sole and entire
          agreement between you and Reslico with respect to the Services and
          supersede all prior and contemporaneous understandings, agreements,
          representations, and warranties, both written and oral, with respect
          to the Services.
        </p>

        <h2>10. Contact Information</h2>
        <p>
          If you have any questions about these Terms or the Services, please
          contact us at{" "}
          <a href="mailto: support@reslico.com">support@reslico.com</a>
        </p>
        <p>
          By using the Services, you accept and agree to be bound by these Terms
          of Service. If you do not agree to these Terms of Service, you should
          not use or access the Services.
        </p>
      </div>
      <Footer />
    </>
  );
}

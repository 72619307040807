import {
  useFiefAuth,
  useFiefIsAuthenticated,
  useFiefUserinfo,
} from "@fief/fief/build/esm/react";
import Button from "react-bootstrap/Button";
import { useCallback } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import mainPageEvent, {
  EVENT_CONTACT_US_CLICKED,
  EVENT_FEATURES_CLICKED,
  EVENT_STATS_CLICKED,
  EVENT_DASHBOARD_CLICKED,
} from "../../services/analytics";

export default function MainNavbar(props) {
  const fiefAuth = useFiefAuth();
  const isAuthenticated = useFiefIsAuthenticated();
  const userinfo = useFiefUserinfo();

  const login = useCallback(() => {
    fiefAuth.redirectToLogin(
      `${window.location.protocol}//${window.location.host}/callback`
    );
  }, [fiefAuth]);

  const logout = useCallback(() => {
    fiefAuth.logout(`${window.location.protocol}//${window.location.host}`);
  }, [fiefAuth]);

  return (
    <Navbar className="navbar" bg="light" expanded="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/#">Reslico</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              onClick={() => {
                mainPageEvent(EVENT_FEATURES_CLICKED);
              }}
              href="/#features"
            >
              Features
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                mainPageEvent(EVENT_STATS_CLICKED);
              }}
              href="/#samples"
            >
              Samples
            </Nav.Link>
            <Nav.Link
              onClick={() => {
                mainPageEvent(EVENT_CONTACT_US_CLICKED);
              }}
              href="/#contact_us"
            >
              Contact us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          {isAuthenticated ? (
            userinfo ? (
              <>
                <NavDropdown
                  title={
                    userinfo.fields.first_name + " " + userinfo.fields.last_name
                  }
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    href="/dashboard/countries"
                    onClick={() => mainPageEvent(EVENT_DASHBOARD_CLICKED)}
                  >
                    Dashboard
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={() => logout()}>
                    Sign out
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              ""
            )
          ) : (
            <>
              <Button variant="outline-primary" onClick={() => login()}>
                Sign in
              </Button>
            </>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

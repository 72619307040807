import ReactGA from "react-ga";

export const eventTracker = (
  category,
  action,
  label = null,
  value = null,
  nonInteraction = false
) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
    value: value,
    nonInteraction: nonInteraction,
  });
};

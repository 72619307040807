export const shouldPrintDebugMessages =
  process.env.REACT_APP_ENV !== "production";

function fnName(str) {
  const fnNameMatcher = /([^(]+)@|at ([^(]+) \(/;
  const regexResult = fnNameMatcher.exec(str);
  return regexResult ? regexResult[1] || regexResult[2] : null;
}

export const consoleDebug = (message) => {
  if (shouldPrintDebugMessages) {
    message = typeof message === "object" ? JSON.stringify(message) : message;
    const logLines = new Error().stack.split("\n");
    const callerName = fnName(logLines[2]) || logLines[2];
    console.log(callerName + ": " + message);
  }
};

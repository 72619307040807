import Countries from "./pages/Countries";
import Providers from "./pages/Providers";

// const Dashboard = React.lazy(() => import("./views/dashboard/Dashboard"));

const routes = [
  { path: "/", exact: true, name: "Home" },
  // { path: "/dashboard", name: "Dashboard", element: Countries },

  { path: "/dashboard/countries", name: "Countries", element: Countries },
  { path: "/dashboard/providers", name: "Providers", element: Providers },
];

export default routes;

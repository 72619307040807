import { CTableDataCell } from "@coreui/react";
import React from "react";
import { useSelector } from "react-redux";
import DataTablePage from "../components/DataTablePage";

const links = {
  kyero: "https://www.keyro.com",
  rightmove: "https://www.rightmove.co.uk",
  tranio: "https://www.tranio.com",
  realting: "https://www.realting.com",
  immonet: "https://www.immonet.de",
  komo: "https://www.komo.co.il",
  madlan: "https://www.madlan.co.il",
  holprop: "https://www.holprop.com",
  immobilienscout24: "https://www.immobilienscout24.de",
};

export default function Providers(props) {
  const providersListingsCounts = useSelector(
    (state) => state.providersListingsCounts
  );

  const entityRenderer = (x) => {
    return (
      <CTableDataCell>
        <a
          href={`${links[x]}`}
          target="_blank"
          rel="noreferrer"
          className="link-primary fw-bold"
        >
          {x}
        </a>
      </CTableDataCell>
    );
  };

  return (
    <DataTablePage
      entityRenderer={entityRenderer}
      dataSelector={providersListingsCounts}
      entityType={"provider"}
      entityTitle={"Providers"}
    />
  );
}

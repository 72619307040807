import { eventTracker } from "../../services/analytics/analytics";
export const EVENT_LOAD = "Main page loaded";
export const EVENT_FEATURES_CLICKED = "Features clicked";
export const EVENT_STATS_CLICKED = "Stats clicked";
export const EVENT_CONTACT_US_CLICKED = "Contact us clicked";
export const EVENT_DOWNLOAD_SAMPLE = "Download sample";
export const EVENT_CONTACT_US_SUBMIT = "Contact us submit";
export const EVENT_PRIVACY_CLICKED = "Privacy clicked";
export const EVENT_TOS_CLICKED = "TOS clicked";
export const EVENT_DASHBOARD_CLICKED = "Dashboard clicked";
export const EVENT_LOGOUT = "Logout";

export const EVENT_SCREEN_LOADED = "Screen loaded";
export const EVENT_CHANGED_TABLE_OPTION = "Changed table option";

export const mainPageEvent = (
  action,
  label = null,
  value = null,
  nonInteraction = false
) => {
  return eventTracker("Main", action, label, value, nonInteraction);
};

export const screenLoadedEvent = (screenName) => {
  return eventTracker(screenName, EVENT_SCREEN_LOADED);
};

export const tablesPageEvent = (
  entity,
  action,
  label = null,
  value = null,
  nonInteraction = false
) => {
  return eventTracker(entity, action, label, value, nonInteraction);
};
export default mainPageEvent;

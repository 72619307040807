import { Button, Spinner } from "react-bootstrap";
import { Download } from "react-bootstrap-icons";

export default function DownloadButton(props) {
  const { onClick, showSpinner } = props;

  return (
    <>
      <Button className="btn btn-primary px-5" onClick={onClick}>
        {showSpinner ? (
          <Spinner
            className="mx-2"
            animation="border"
            variant="light"
            size="sm"
          />
        ) : (
          <Download className="mx-2" />
        )}
      </Button>
    </>
  );
}

import CIcon from "@coreui/icons-react";
import { CTableDataCell } from "@coreui/react";
import React from "react";
import { useSelector } from "react-redux";
import FlagsSvgs from "../../utils/FlagsSvgs";
import DataTablePage from "../components/DataTablePage";

export default function Countries(props) {
  const countriesListingsCounts = useSelector(
    (state) => state.countriesListingsCounts
  );

  const entityRenderer = (x) => {
    return (
      <>
        <CTableDataCell>
          <CIcon size="xl" icon={FlagsSvgs[x]} />
        </CTableDataCell>
        <CTableDataCell>
          <strong>{x}</strong>
        </CTableDataCell>
      </>
    );
  };

  return (
    <DataTablePage
      entityRenderer={entityRenderer}
      entityColSpan={2}
      dataSelector={countriesListingsCounts}
      entityType={"country"}
      entityTitle={"Countries"}
    />
  );
}

export function percentsNumber(number, places = undefined) {
  if (number === undefined || number === null) return number;

  number = Number(number) * 100;
  if (places === undefined) {
    const absNumber = Math.abs(number);
    places = 0;
    if (0 < absNumber && absNumber < 1) {
      places = 2;
    } else if (1 <= absNumber && absNumber < 10) {
      places = 1;
    }
  }

  return abbreviateNumber(number, places, places);
}

export function abbreviateNumber(
  number,
  maxPlaces,
  forcePlaces = 2,
  forceLetter = false
) {
  number = Number(number);
  forceLetter = forceLetter || false;
  if (forceLetter !== false) {
    return annotateNumber(number, maxPlaces, forcePlaces, forceLetter);
  }
  var abbr;
  if (number >= 1e12) {
    abbr = "T";
  } else if (number >= 1e9) {
    abbr = "B";
  } else if (number >= 1e6) {
    abbr = "M";
  } else if (number >= 1e3) {
    abbr = "K";
  } else {
    abbr = "";
  }
  return annotateNumber(number, maxPlaces, forcePlaces, abbr);
}

function annotateNumber(number, maxPlaces, forcePlaces, abbr) {
  // set places to false to not round
  var rounded = 0;
  switch (abbr) {
    case "T":
      rounded = number / 1e12;
      break;
    case "B":
      rounded = number / 1e9;
      break;
    case "M":
      rounded = number / 1e6;
      break;
    case "K":
      rounded = number / 1e3;
      break;
    default:
    case "":
      rounded = number;
      break;
  }
  if (maxPlaces !== false) {
    var test = new RegExp("\\.\\d{" + (maxPlaces + 1) + ",}$");
    if (test.test("" + rounded)) {
      rounded = rounded.toFixed(maxPlaces);
    }
  }
  if (forcePlaces !== false) {
    rounded = Number(rounded).toFixed(forcePlaces);
  }
  return rounded + abbr;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";

import * as images from "../../images";
import * as svgs from "../../svgs";

export default function Header(props) {
  return (
    <>
      <section className="page-header gradient">
        <div className="container pt-3">
          <div className="row align-items-center justify-content-end gx-5">
            <div className="col-md-6">
              <h2>Welcome to Reslico</h2>
              <p>
                We provide clean, structured data for real estate listings all
                over the world. For sale & rent listings gathered from publicly
                available different listings sites, cleaned up and ready for you
                to complete your analysis towards your next step in the real
                estate business.
              </p>
            </div>
            <div className="col-md-6">
              <img
                className="house-image"
                src={images.main_house}
                alt="Header"
              />
            </div>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#ffffff"
            fillOpacity="1"
            d="M0,96L80,101.3C160,107,320,117,480,149.3C640,181,800,235,960,224C1120,213,1280,139,1360,101.3L1440,64L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </section>
      <section className="countries">
        <div className="container text-center">
          <div className="row text-center">
            <h3>
              We collect real estate data mainly for these countries, <br />
              but we have data for 100+ countries around the world
            </h3>
          </div>
          <Container className="container row g-6" fluid>
            <div className="col-sm-2 flag">
              <Image
                className="img-fluid"
                src={svgs.united_kingdom}
                alt="united_kingdom"
              ></Image>
            </div>
            <div className="col-sm-2 flag">
              <Image className="img-fluid" src={svgs.spain} alt="spain"></Image>
            </div>
            <div className="col-sm-2 flag">
              <Image
                className="img-fluid"
                src={svgs.germany}
                alt="germany"
              ></Image>
            </div>
            <div className="col-sm-2 flag">
              <Image
                className="img-fluid"
                src={svgs.portugal}
                alt="portugal"
              ></Image>
            </div>
            <div className="col-sm-2 flag">
              <Image
                className="img-fluid"
                src={svgs.france}
                alt="france"
              ></Image>
            </div>
            <div className="col-sm-2 flag">
              <Image className="img-fluid" src={svgs.italy} alt="italy"></Image>
            </div>
          </Container>
        </div>
      </section>
    </>
  );
}

import Footer from "../main/Footer";
import MainNavbar from "../main/Navbar";
import "./Legal.css";

export default function Privacy(props) {
  return (
    <>
      <MainNavbar />
      <div className="paragraph">
        <h1>Reslico Privacy Policy</h1>
        <p>
          This Privacy Policy describes the policies and procedures of Reslico
          (“we”, “our” or “us”) on the collection, use and disclosure of your
          information on www.reslico.com (the “Site”) and the services,
          features, content or applications we offer (collectively with the
          Site, the “Services”). We receive information about you from various
          sources, including: (i) your use of the Services generally; and (ii)
          from third party websites and services. When you use the Services, you
          are consenting to the collection, transfer, manipulation, storage,
          disclosure and other uses of your information as described in this
          Privacy Policy.
        </p>

        <h2>1. What Does This Privacy Policy Cover?</h2>
        <p>
          This Privacy Policy covers the treatment of personally identifiable
          information (“Personal Information”) gathered when you are using or
          accessing the Services. This Privacy Policy also covers our treatment
          of Personal Information that our business partners share with us or
          that we share with our business partners.
        </p>

        <p>
          This Privacy Policy does not apply to the practices of third parties
          that we do not own or control, including but not limited to any third
          party websites, services and applications (“Third Party Services”)
          that you elect to access through the Service or to individuals that we
          do not manage or employ. While we attempt to facilitate access only to
          those Third Party Services that share our respect for your privacy, we
          cannot take responsibility for the content or privacy policies of
          those Third Party Services. We encourage you to carefully review the
          privacy policies of any Third Party Services you access.
        </p>

        <p>
          Your information may be maintained on and transferred to computers
          located outside of your state, province, country or other governmental
          jurisdiction where the privacy laws may not be as protective as those
          in your jurisdiction. We may transfer personal information to anywhere
          in the world and process it there. By using the Services and
          submitting your Personal Information you consent to such transfer and
          storage.
        </p>

        <h2>2. Information We Collect From You</h2>
        <p>
          The information we gather enables us to personalize, improve and
          continue to operate the Services. In connection with certain aspects
          of the Services, we may request, collect and/or display some of your
          Personal Information, as well as other information in connection with
          your use of, and access to, the Services. We collect the following
          types of information from our users.
        </p>

        <h3>a. Information You Choose to Provide to Us:</h3>
        <p>
          We collect information you provide directly to us. For example, if you
          set up an account, we will require you to provide an email address.
          You may also decide to provide us with your full name and telephone
          number, or other contact information and information about yourself
          and your interests.
        </p>

        <h3>b. Information That is Collected Automatically:</h3>
        <p>
          When you interact with our Services (including visits to our Site and
          interaction with emails we may send you), we automatically receive and
          record information from your web browser. This includes, for
          instances, your IP address, device information and cookie information.
          It also includes cookies and similar technical identifiers, that
          identify your browser or device: we or third parties that we work with
          (whether as independent companies or as service providers to us) place
          and read cookies on your browser, along with cookie “identifiers” that
          identify you as you interact with our website. These third parties may
          place cookies when you visit other sites, as well. Please see Section
          6 below regarding these cookies, and how to opt out of certain
          marketing uses of cookies.
        </p>

        <p>
          Similar, we may collect other device-specific information if you
          access the Services using a mobile device. This may include but is not
          limited to unique device identifiers, network information, and
          hardware model, as well as information about how the device interacts
          with our Services.
        </p>

        <h3>c. Email Communications:</h3>
        <p>
          We may receive a confirmation when you open an email from us. We use
          this confirmation to improve our customer service.
        </p>

        <h3>d. Aggregate Information:</h3>
        <p>
          We collect (or create) statistical information about how users,
          collectively, use the Services (“Aggregate Information”). Some of this
          information is derived from Personal Information. This statistical
          information is not Personal Information and cannot be tied back to you
          or your web browser. We may use the information that we collect about
          you or that you provide to us, including any Personal Information, (i)
          in connection with our business; (ii) in any way we may describe when
          you provide the information; and (iii) for any other purpose with your
          consent.
        </p>

        <h2>
          3. Information We Receive From Our Customers and Business Partners
        </h2>
        <p>
          We also receive information from our customers and business partners
          that includes personal information about their own customers,
          contacts, or other personal information they may provide to us.
        </p>

        <p>
          This may include (for instance) an individual’s phone number, name, or
          email address, and we may also receive or enhance this information
          with other information about individuals such as their interests (for
          instance, the type of home or neighborhood they may be interested in
          purchasing). These customers and partners may provide us rights to use
          and share this information, including for monetization purposes such
          as sharing with or selling to third party marketers. We provide an
          “opt out” with respect to these purposes. If you wish to opt out of
          our sale of your personal information for marketing purposes, please
          send your email, telephone number, name and address to us at
          https://reslico.com/#contact_us. We provide additional information
          about your online marketing choices in Section 7 below.
        </p>

        <h2>4. How We Use the Information We Collect or Receive</h2>
        <p>
          We may use your information for various purposes, depending on the
          type of information we have collected from and about you and the
          specific Services you use, in order to:
        </p>

        <ul>
          <li>Respond to your requests for information</li>
          <li>Provide you with updates and information about our Services</li>
          <li>Verify your identity and for fraud prevention</li>
          <li>
            Process your payments (as explained above, such payments will be
            processed by our third-party payment processor, not by us)
          </li>
          <li>
            Help us better understand your interests and needs to improve the
            Services
          </li>
          <li>
            Engage in analysis, research and reports regarding the use of our
            Services
          </li>
          <li>
            Contact you regarding our (or other parties’) products, services,
            surveys, or promotions (in accordance with any privacy preferences
            you have expressed to us)
          </li>
          <li>
            Tailor the content and information that we may send or display to
            you, re-target content to you through our software application and
            elsewhere, and to otherwise personalize your experiences while using
            our Services
          </li>
          <li>
            Provide, manage and improve the Services, and test and create new
            products, features and services
          </li>
          <li>
            Understand and resolve any technical and security issues reported on
            our Sites
          </li>
          <li>
            Comply with the law and protect the safety, rights, property or
            security of Hive, the Services and the general public
          </li>
          <li>
            Enforce any legal terms that govern your relationship with us or
            your use of the Services
          </li>
          <li>
            Share it with third parties for their own marketing purposes, with
            respect to the data described in Section 3 above (“Information We
            Receive From Our Customers and Business Partners”)
          </li>
          <li>For any other purpose with your consent</li>
        </ul>
        <p>
          Please note that we may combine information that we collect from you
          and about you (including automatically generated information) with
          information that we obtain about you from third parties, and use such
          combined information in accordance with this Privacy Policy.
        </p>

        <h3>Aggregate/De-identified/Anonymous data.</h3>
        <p>
          We may aggregate, de-identify and/or anonymize any information
          collected through the Services so that such information is no longer
          linked to your personally identifiable information (such as your name,
          address, phone number or email address). We may use aggregated and
          anonymized information for any purpose, including, without limitation,
          for research and marketing purposes.
        </p>

        <h2>5. Cookies and Similar Technologies</h2>
        <p>
          Cookies are pieces of text that may be provided to your computer
          through your web browser when you access a website. Your browser
          stores cookies in a manner associated with each website you visit. We
          use cookies to enable our servers to recognize your web browser and
          tell us how and when you visit the Site and otherwise use the Services
          through the Internet. We sometimes rely on service partners and
          business partners to deploy and read these cookies, whether on our
          behalf or in order to operate their own services (which we may then
          use).
        </p>

        <p>
          Most browsers have an option for turning off the cookie feature, which
          will prevent your browser from accepting new cookies, as well as
          (depending on the sophistication of your browser software) allowing
          you to decide on acceptance of each new cookie in a variety of ways.
          However, turning off cookies may reduce the functionality of some
          websites and web operations.
        </p>

        <p>
          This Privacy Policy covers our use of cookies only and does not cover
          the use of cookies by third parties. We do not control when or how
          third parties place cookies on your computer. For example, third party
          websites to which a link points may set cookies on your computer.
        </p>

        <p>We use the following types of cookies:</p>
        <ul>
          <li>
            <u>Essential Cookies</u>. Essential Cookies are required for
            providing you with features or services that you have requested. For
            example, certain Cookies enable you to log into secure areas of our
            Services. Disabling these Cookies may make certain features and
            services unavailable.
          </li>
          <li>
            <u>Functional Cookies</u>. Functional Cookies are used to record
            your choices and settings regarding our Services, maintain your
            preferences over time and recognize you when you return to our
            Services. These Cookies help us to personalize our content for you,
            greet you by name, and remember your preferences (for example, your
            choice of language or region).
          </li>
          <li>
            <u>Re-targeting/Advertising Cookies</u>. Retargeting/Advertising
            Cookies collect data about your online activity and identify your
            interests so that we can provide advertising that we believe is
            relevant to you. For more information about this, please see Section
            6 below.
          </li>
        </ul>

        <h2>6. Interest-Based Advertisements: Your Choices</h2>
        <p>
          We allow third-party ad networks, including third-party ad servers, ad
          agencies, ad technology vendors and research firms, to serve
          advertisements through the Services. These advertisements may be
          targeted to users who fit certain general profile categories or
          display certain preferences or behaviors (“Interest-Based Ads”).
          Information for Internet-Based Ads (including Personal Information)
          may be provided to us by you, or derived from the usage patterns of
          particular users on the Services and/or services of third parties.
          Such information may be gathered through tracking users’ activities
          across time and unaffiliated properties, including when you leave the
          Services. To accomplish this, our service providers may deliver
          cookies, including a file (known as a “web beacon”) from an ad network
          to you through the Services. Web beacons allow ad networks to provide
          anonymized, aggregated auditing, research and reporting for us and for
          advertisers. Web beacons also enable ad networks to serve targeted
          advertisements to you when you visit other websites. Web beacons allow
          ad networks to view, edit or set their own cookies on your browser,
          just as if you had requested a web page from their site. (We describe
          “cookie” technologies in greater detail in Section 6 above.
        </p>

        <p>
          Through the DAA and Network Advertising Initiative (“NAI”), several
          media and marketing associations have developed an industry
          self-regulatory program to give consumers a better understanding of,
          and greater control over, ads that are customized based a consumer’s
          online behavior across different websites and properties. To make
          choices about Interest-Based Ads from participating third parties,
          including to opt-out of receiving behaviorally targeted advertisements
          from participating organizations, please visit the DAA’s or NAI’s
          consumer opt-out pages, which are located at
          http://www.networkadvertising.org/choices/ or
          www.aboutads.info/choices.
        </p>

        <p>
          Please also note that you can opt out of your sale of other personal
          information about you, in other circumstances, by using the “opt out”
          method we provide in Section 3 above.
        </p>

        <h2>7. Is Information About Me Secure?</h2>
        <p>
          We seek to protect Personal Information to ensure that it is kept
          private; however, we (like any company operating online) cannot
          guarantee the security of any information. Unauthorized entry or use,
          hardware or software failure, and other factors, may compromise the
          security of user information at any time. We therefore cannot
          guarantee or warrant that any techniques we use (no matter how
          sophisticated) will in all cases prevent unauthorized access to
          information about you that we store, Personal Information or
          otherwise.
        </p>

        <h2>8. What Happens When There Are Changes to this Privacy Policy?</h2>
        <p>
          We may amend this Privacy Policy from time to time, by posting it to
          the website with a new “update” date. Use of information we collect
          now is subject to the Privacy Policy in effect at the time such
          information is collected. If we make material changes in the way we
          collect or use information, we will provide reasonable notice by
          either sending an email to affected users or by linking to the new
          Privacy Policy in a prominent manner (such as in a prominent color or
          font). A user is bound by any changes to the Privacy Policy when he or
          she uses the Services after such changes have been first posted.
        </p>

        <h2>9. What If I Have Questions or Concerns?</h2>
        <p>
          If you have any questions or concerns regarding privacy using the
          Services, please send us a detailed message to privacy@reslico.com. We
          will make every effort to resolve your concerns.
        </p>

        <p>
          This privacy policy is effective by <b>September 5th, 2022</b>
        </p>
      </div>
      <Footer />
    </>
  );
}

import React, { useEffect } from "react";
import ReactGA from "react-ga";

import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { FiefAuthProvider } from "@fief/fief/react";

import Main from "./pages/main/Main";
import Privacy from "./pages/legal/Privacy";
import { shouldPrintDebugMessages } from "./utils/Utils";
import Callback from "./pages/authentication/Callback";
import RequireAuth from "./pages/authentication/RequireAuth";
import TermsOfService from "./pages/legal/TermsOfService";
import { Provider } from "react-redux";
import store from "./utils/Store";
import Dashboard from "./dashboard/layout/Dashboard";
import Logout from "./pages/authentication/Logout";

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
  debug: shouldPrintDebugMessages,
});

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <FiefAuthProvider
        baseURL={process.env.REACT_APP_FIEF_ENDPOINT}
        clientId={process.env.REACT_APP_FIEF_CLIENT_ID}
      >
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route exact path="/privacy" element={<Privacy />} />
              <Route
                exact
                path="/terms_of_service"
                element={<TermsOfService />}
              />
              <Route exact path="/callback" element={<Callback />} />
              <Route exact path="/logout" element={<Logout />} />
              <Route
                path="*"
                element={
                  <RequireAuth>
                    <Dashboard />
                  </RequireAuth>
                }
              />
            </Routes>
          </BrowserRouter>
        </Provider>
      </FiefAuthProvider>
    </>
  );
}

export default App;
